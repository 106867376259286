<template>
	<div class="page page-agenda">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="live-programme container">
			<div v-for="channel in channels" class="live-programme__channel">
				<h2>{{ channel.title }}</h2>
				<div class="live-programme__content">
					<swiper v-if="filterSessionList(channel).length > 0" :options="swiperOptions">
						<swiper-slide v-for="session in filterSessionList(channel)" :key="session.id" class="agenda__session">
							<div
								class="agenda__session__content"
								:class="{ 'is-striped': session.sponsored, 'is-live': !session.sponsored && isLive(session) }"
								:style="[session.sponsored && !session.colour && { backgroundColor: '#e3e3ff' }, session.colour && { backgroundColor: session.colour }]"
							>
								<div v-if="isLive(session) && session.visitable === true && session.liveProgress" class="agenda__session__liveprogress">
									<div :style="{ width: `${session.liveProgress}%` }"></div>
								</div>
								<div class="agenda__session__time">
									<div>
										<p>
											<strong v-if="isLive(session) && session.visitable === true" class="agenda__session__livenow">Live</strong>
											<span
												>{{ session.start | dateTextFormat(session.date) }} &bull; {{ session.start | timeFormat(session.date) }} -
												{{ session.end | timeFormat(session.date) }}</span
											>
											CET
										</p>
									</div>
								</div>
								<h2>
									<router-link v-if="session.visitable === true" :to="{ name: 'AgendaSession', params: { url: session.url } }">{{ session.title }}</router-link>
									<span v-else>{{ session.title }}</span>
								</h2>
								<h3 v-if="session.session_type && session.session_type.name">{{ session.session_type.name }}</h3>

								<router-link
									v-if="session.external !== true && isLogged && session.live_track && session.live_track.url && isLive(session)"
									:to="{ name: 'LiveTrack', params: { url: session.live_track.url } }"
									class="agenda__status is-small is-live"
								>
									<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
									<span>Join session</span>
								</router-link>
								<a
									v-if="session.external === true && session.user_allowed === true && isLogged && isLiveExternal(session)"
									class="agenda__status is-small is-live"
									:href="session.external_url"
									target="_blank"
								>
									<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
									<span>Join session</span>
								</a>
								<div v-if="session.speakers && session.speakers.length > 0" class="agenda__session__speakers">
									<router-link v-for="speaker in session.speakers" :key="speaker.id" :to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }" class="agenda__session__speaker">
										<div class="image">
											<img :src="speaker.profile_picture | imageUrl('thumbnail')" />
										</div>
										<div>
											<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
											<h4>{{ speaker.country }}</h4>
										</div>
									</router-link>
								</div>
								<div v-if="isLogged" class="agenda__favourite" :class="{ 'is-favourited': session.favourite }">
									<a @click.prevent="toggleFavourite(session)">
										<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
									</a>
								</div>
							</div>
						</swiper-slide>
						<span slot="button-prev" class="swiper-small-prev">
							<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
						</span>
						<span slot="button-next" class="swiper-small-next">
							<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
						</span>
					</swiper>
					<div v-else>
						<div class="agenda__status is-full is-processing">
							<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
							<span>No more sessions in this channel for today, please check the full agenda.</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import fuzzysort from 'fuzzysort';
	import userSession from '../utils/session';
	import moment from 'moment-timezone';
	import meta from '../utils/meta';
	import platformSettings from '../utils/platformSettings';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	export default {
		name: 'Agenda',
		data() {
			return {
				programme: {},
				dates: [],
				sessionList: [],
				currentDate: null,
				currentList: false,
				userSession: userSession.state,
				title: '',
				subtitle: '',
				background: '',
				channels: [],
				swiperOptions: {
					slidesPerView: 1,
					spaceBetween: 10,
					allowTouchMove: true,
					navigation: {
						nextEl: '.swiper-small-next',
						prevEl: '.swiper-small-prev',
					},
					breakpoints: {
						1024: {
							slidesPerView: 2,
							spaceBetween: 30,
							allowTouchMove: false,
						},
					},
				},
				refreshInterval: null,
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			allCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].length;
			},
			favCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].filter(e => e.favourite).length;
			},
			isLogged() {
				return this.userSession.loggedIn;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		beforeDestroy() {
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		mounted() {
			this.refreshInterval = window.setInterval(() => {
				this.refreshLiveProgress();
			}, 60 * 1000);
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('/page-live-programme'), $http.get('/channels'), $http.get(`/event-programme`)]).then(
				$http.spread((page, chans, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setChannels(chans);
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('/page-live-programme'), $http.get('/channels'), $http.get(`/event-programme`)]).then(
				$http.spread((page, chans, data) => {
					this.setPage(page);
					this.setChannels(chans);
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setChannels({ data }) {
				this.channels = data;
			},
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				let sessions = data.data;
				sessions = sessions.reduce(function(r, a) {
					r[a.date] = r[a.date] || [];
					r[a.date].push(a);
					return r;
				}, Object.create(null));
				this.dates = Object.keys(sessions).sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
				this.currentDate = this.dates.length > 0 ? this.dates[0] : null;

				if (this.dates.length > 0) {
					let now = moment().tz('CET');
					let cgStart = moment(this.platformSettings.congressStart);
					if (now.isBefore(cgStart)) {
						now = cgStart;
					}

					let index = this.dates.findIndex(e => {
						return now.isSame(moment(e, 'YYYY-MM-DD', 'CET'), 'day');
					});
					if (index > 0) {
						this.currentDate = this.dates[index];
					}
				}

				Object.keys(sessions).map(key => {
					sessions[key].sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					sessions[key].sort((a, b) =>
						new Date(`${a.date} ${a.start}`) > new Date(`${b.date} ${b.start}`) ? 1 : new Date(`${a.date} ${a.start}`) < new Date(`${b.date} ${b.start}`) ? -1 : 0
					);
					sessions[key] = sessions[key].filter(e => this.isVisible(e));

					sessions[key].map(e => {
						if (e.session_type && e.session_type.name) {
							e.searchType = e.session_type.name;
						}

						if (e.speakers && e.speakers.length > 0) {
							e.searchSpeakers = '';
							e.speakers.map(s => {
								e.searchSpeakers += s.name;
								return s;
							});
						}
						return e;
					});
				});

				this.programme = sessions;
				this.sessionList = this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
				this.refreshLiveProgress();
			},
			selectDate(date) {
				this.currentDate = date;
				this.sessionList = this.currentList ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
			},
			doSearch() {
				if (this.search.length > 1) {
					const results = fuzzysort.go(this.search, this.programme[this.currentDate], { allowTypo: false, keys: ['title', 'searchType', 'searchSpeakers'] });
					this.sessionList = results.map(e => e.obj);
				} else {
					this.sessionList = this.programme[this.currentDate];
				}
			},
			toggleFavourite(session) {
				session.favourite = !session.favourite;
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {},
					() => {
						session.favourite = !session.favourite;
					}
				);
			},
			selectList(fav) {
				if (fav && !this.isLogged) return;
				this.currentList = fav;
				this.sessionList = fav ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
			},
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isVisible({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBefore(dEnd);
			},
			calculateLiveProgress({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				let total = moment.duration(dEnd.diff(dStart)).asMinutes();
				let current = moment.duration(now.diff(dStart)).asMinutes();

				return Math.ceil((current * 100) / total);
			},
			refreshLiveProgress() {
				if (this.sessionList) {
					for (let i in this.sessionList) {
						if (this.isLive(this.sessionList[i])) {
							this.$set(this.sessionList[i], 'liveProgress', this.calculateLiveProgress(this.sessionList[i]));
						} else {
							this.$delete(this.sessionList[i], 'liveProgress');
						}
					}
				}
			},
			filterSessionList(channel) {
				return this.sessionList.filter(session => session.channel && session.channel.id === channel.id);
			},
		},
	};
</script>
